/* eslint-disable */
import Vue from 'vue'
import './registerServiceWorker'
import router from './router'
import { useFavicon } from '@vueuse/core'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import store from './store'
import 'jquery'
import '../public/assets/plugins/bootstrap/dist/js/bootstrap.bundle.min'
import '../public/scss/style.scss'
import { directive as onClickaway } from 'vue-clickaway'
import axios from "axios";
import QRCode from 'qrcode'
import VueCookies from 'vue-cookies'
import ChatReportDesk from "@/components/chatReportDesk";
import dropDownRole from "@/components/dropDownRole";
import ToggleButton from 'vue-js-toggle-button'
import VCalendar from 'v-calendar';
import Calendar from 'v-calendar/src/components/Calendar.vue'
import DatePicker from 'v-calendar/src/components/DatePicker.vue'
import i18n from "./i18n";
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
import ScreenSpinner from '@/components/ScreenSpinner.vue'
import UserAgreement from "@/components/UserAgreement.vue";
import QRCodeStyling from "qr-code-styling";
import directives from "@/directives";
import Vue2TouchEvents from 'vue2-touch-events'
import VoiceRecognitionView from "@/views/VoiceRecognitionView.vue";
import defaultNewInput from "@/components/redesign/defaultNewInput.vue";
import {setPalette} from "@/paletteFunction";
Vue.use(Vue2TouchEvents)
import {VueMaskPlugin} from "v-mask";
//import {grantSubscribe} from "@/services/cloudMessaging";
import navigation from "@/components/navigation.vue";
import {VueWindowSizePlugin} from "vue-window-size/option-api";
import {createYmapsVue2} from "vue-yandex-maps";
import IndicatorCounter from "@/components/redesign/IndicatorCounter.vue";
import AddToCollection from "@/components/redesign/addToCollection.vue";
import SnackbarListAnimation from "@/components/redesign/snackbarListAnimation.vue";
import SnackbarItem from "@/components/redesign/snackbarItem.vue";
import ModalContainer from "@/components/redesign/modalContainer.vue";
import FormItem from "@/components/redesign/FormItem.vue";
import DefaultNewInput from "@/components/redesign/defaultNewInput.vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";
import AlertModal from "@/components/redesign/alertModal.vue";

// Register components in your 'main.js'
Vue.use(createYmapsVue2({
  apikey: '8b25a3a3-8a36-4aea-9945-84ac6708b00f',
  lang: 'ru_RU',
  initializeOn: 'onPluginInit'
}));
Vue.component('calendar', Calendar)
Vue.component('date-picker', DatePicker)
// Vue.component('language-switcher', LanguageSwitcher)
// /*import VueColumnsResizable from 'vue-columns-resizable';
// Vue.use(VueColumnsResizable);
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
  // ...other defaults
})
Vue.config.productionTip = false
directives.forEach(directive=>{
  Vue.directive(directive.name, directive)
})
Vue.directive('on-clickaway', onClickaway)
Vue.use(ToggleButton)
Vue.use(VueCookies, { expire: '90d'})
Vue.use(VueWindowSizePlugin)

axios.defaults.headers.common = {};
axios.defaults.headers.common['Content-Type'] = 'text/plain'
axios.defaults.headers.post['Content-Type'] = 'text/plain'
axios.defaults.headers.get['Content-Type'] = 'text/plain'
axios.defaults.headers.common.accept = 'application/json'

if(document.location.hostname.indexOf('itrielt.ru') !== -1 || document.location.hostname.indexOf('itrealt.com') !== -1 || document.location.hostname.indexOf('itarealty.com') !== -1) axios.defaults.baseURL = 'https://api.itrielt.ru'
else axios.defaults.baseURL = 'https://building-api.letsbot.ru'

if(document.location.hostname.indexOf('tochno.itrielt.ru') !== -1) axios.defaults.baseURL = 'https://tochno-api.itrielt.ru'
if(document.location.hostname.indexOf('tochno-dev.itrielt.ru') !== -1) axios.defaults.baseURL = 'https://tochno-api.itrielt.ru'
if(document.location.hostname.indexOf('tochno.letsbot.ru') !== -1) axios.defaults.baseURL = 'https://tochno-api.itrielt.ru'
if(document.location.port === '8081') axios.defaults.baseURL = 'http://localhost:8080'
Vue.prototype.$http = axios

new Vue({
  el: '#main-wrapper',
  router,
  store,
  i18n,
  data () {
    return {
      sendHeightLink:'',
      bannerTextList:['','',''],
      bannerText:'До нового года осталось:',
      visibleModalStream:false,
      intervalBanner:null,
      formattedTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      visibleTimer: true,
      iframe: false,
      qrLinkActive:false,
      foreignSite:false,
      visibleFuncCard:true,
      visibleModalCard:false,
      visibleModalCoins:false,
      selectedOpt:'accruals',
      filterHistory:'all',
      history:[],
      usedAuth:false,
      menu: {},
      newsAnswer:{
        answerdata:''
      },
      host: null,
      visibleCongratulationsNews:false,
      visibleModalNews:false,
      listNews:[],
      site: {
        id: null,
        domain: null,
        logo: null,
        name: null,
        background: null
      },
      qr: null,
      optionsRole:[],
      optionsCountries:[
        // {id:'ru', value:'Россия'},
        // {id:'tr', value:'Турция'},
      ],
      optionsDevelopers:[
        // {id:1, value:'AVA'},
        // {id:2, value:'Застройщик №2'},
      ],
      selectedRole:{},
      selectedCountry:{id:'ru', value:'Россия'},
      selectedDeveloper:{id:1, value:'AVA'},
      areShowInfoCurator:true,
      errorInfoTitle:false,
      compName: null,
      response: null,
      responseStatus: this.$i18n.t('Успех'),
      openLeft: false,
      shareData: {},
      menus: [],
      init: false,
      hostName: null,
      installPrompt: null,
      connection:null,
      agreement:1,
      showChatDesktop: false,
      initialChat: false,
      chatIndicatorValue:0,
      visibleAddToCollection:false,
      listBar:[],
      snackbarId:0,
      visibleMainShareModal:false,
      visibleResponseModal:false,
    }
  },
  components: {
    UserAgreement,
    ChatReportDesk,
    dropDownRole,
    LanguageSwitcher,
    VoiceRecognitionView,
    defaultNewInput,
    navigation,
    ScreenSpinner,
    IndicatorCounter,
    AddToCollection,
    SnackbarListAnimation,
    SnackbarItem,
    ModalContainer,
    FormItem,
    DefaultNewInput,
    DefaultButton,
    AlertModal,
  },
  computed: {
    blackTheme(){
      return this.$store.getters.getBlackTheme
    },
    goConnect(){
      return this.$store.getters.getGoConnect
    },
    /*visibleInstall(){
      return this.$store.getters.getVisibleInstall
    },*/
    messages(){
      return this.$store.getters.getMessages
    },
    compl () {
      return this.$store.getters.getCompl
    },
    uuid () {
      return this.$store.getters.getUid
    },
    object () {
      return this.$store.getters.getObject
    },
    objectBookMark () {
      return this.$store.getters.getBookmark
    },
    profile () {
      return this.$store.getters.getProfile
    },
    role () {
      return this.$store.getters.getRole
    },
    roles () {
      return this.$store.getters.getRoles
    },
    isRoomsSubdomain() {
      return this.$store.getters.getCurrentDomain === 'rooms'
    }
  },
  updated() {
    if(!this.init && this.$route && this.$route.name) {
      if(this.uuid === 'unknown') {
        var uuid = this.generateUUID()
        this.$store.dispatch('SET_UUID', uuid)
        if(location.pathname == '/' || location.pathname.indexOf('/invite') !== -1 || location.pathname.indexOf('/client/') !== -1 || location.pathname.indexOf('/ambassador/') !== -1 || location.href.indexOf('utm') !== -1) {
          axios.post('/api/log-visit', {
            uuid: uuid,
            url: location.href
          })
        }
        location.reload()
      } else {
        if(!this.usedAuth) {
          this.usedAuth=true
          axios.defaults.headers.common['Token'] = this.uuid
          if(location.pathname == '/' || location.pathname.indexOf('/invite') !== -1 || location.pathname.indexOf('/client/') !== -1 || location.pathname.indexOf('/ambassador/') !== -1 || location.href.indexOf('utm') !== -1) {
            axios.post('/api/log-visit', {
              uuid: this.uuid,
              url: location.href
            })
          }
          this.getAuth()
        }
      }
    }
  },
  /* beforeCreate() {
     window.iFramePositionFixPolyfillConfiguration = {
       debug: false,
       parent: window.parent,
       parentScrollTopProperty: 'pageYOffset'
     }
   },*/
  mounted() {
    Array.prototype.hasAll = function(a) {
      var hash = this.reduce(function(acc, i) { acc[i] = true; return acc; }, {});
      return a.every(function(i) { return i in hash; });
    }; //Добавляем у массивов функцию hasAll - которая проверяет, что массив b хотя бы в одном экзепляре содержит каждый элемент массива a
    //grantSubscribe();
    this.iframe = this.inIframe()
    if(this.$route.path === '/') this.iframe = false
    console.log('iframe:' + this.inIframe())
    this.hostName = document.location.protocol+'//'+document.location.host
    if(this.hostName.indexOf('.com')!==-1)this.foreignSite=true //проверка на иностранный сайт
    else this.foreignSite=false
    if(this.hostName==='https://building.letsbot.ru' || this.hostName==='http://localhost:8080'){
      this.$store.commit('SET_CHAT_PORT', '4445')
      this.$store.commit('SET_GO_CONNECT', 'https://itrielt.ru:5556')
      this.sendHeightLink='https://avadom.mirrors.dev.southmedia.ru'
    } else {
      this.$store.commit('SET_CHAT_PORT', '4444')
      this.$store.commit('SET_GO_CONNECT', 'https://story.itrielt.ru')
      this.sendHeightLink='https://avadom.ru'
    }
    this.shareData = {
      title: '',
      text: '',
      url: this.hostName
    }
    this.qr = new QRCodeStyling({
      width: 2048,
      height: 2048,
      type: "canvas",
      data: this.shareData.url,
      image: this.hostName+'/itrieltQr.svg',
      dotsOptions: {
        color: "#102D4D",
        type: "extra-rounded"
      },
      cornersSquareOptions: {
        type: 'extra-rounded'
      },
      cornersDotOptions: {
        type: 'dot'
      },
      backgroundOptions: {
        color: "#fff",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 20
      }
    });
    var hostname = document.location.hostname
    var loadInfo = localStorage.getItem('openSiteID')
    var loadInfoURL = localStorage.getItem('openSiteURL')
    if(loadInfo) {
      hostname = loadInfoURL
      axios.defaults.headers.common['site'] = hostname
      axios.get('/api/load-account?id='+loadInfo, {
        headers: {
          'Content-Type': 'text/plain'
        }}).then(res => {
        // const favicon = document.getElementById("favicon");
        this.site = res.data
        this.host = this.site.domain+'.itrielt.ru'
        const icon = useFavicon()
        icon.value = '/'+this.site.domain+".ico"
        // favicon.href = "/"+this.site.domain+".ico";
        document.title = this.site.name
        this.selectedDeveloper = {id:this.site.id, value:this.site.name}

        var link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
        link.href = '/'+this.site.domain+'.ico';


        //this.$store.dispatch('SET_CURRENCY', this.site.currency)  //Раскомментировать, когда с сервера будет приходить валюта - currency
        if(this.site.domain==='dubai') this.$store.dispatch('SET_CURRENCY', {unit:'AED', description:'AED'}) //Заглушка - убрать, когда с сервера будет приходить валюта - currency
        else this.$store.dispatch('SET_CURRENCY', {unit:'₽', description:'RUB'})

        this.$store.dispatch('SET_CURRENT_DOMAIN', this.site.domain)
        if(this.hostName === 'https://itrielt.ru' || this.hostName === 'https://itrealt.com' || this.hostName === 'https://itarealty.com') {
          document.title = 'ITRIELT'
        }
        else if(this.hostName==='https://building.letsbot.ru' || this.hostName==='http://localhost:8080' || this.hostName==='http://localhost:8081') {
          localStorage.setItem('openSiteURL', this.host)
          localStorage.setItem('openSiteID', this.site.id)
        }
      })
    } else {
      axios.defaults.headers.common['site'] = hostname
      axios.get('/api/load-account').then(res => {
        // const favicon = document.getElementById("favicon");
        this.site = res.data
        this.host = this.site.domain+'.itrielt.ru'
        const icon = useFavicon()
        icon.value = '/'+this.site.domain+".ico"
        // favicon.href = "/"+this.site.domain+".ico";
        document.title = this.site.name
        this.selectedDeveloper = {id: this.site.id, value: this.site.name}

        var link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
        link.href = '/' + this.site.domain + '.ico';

        //this.$store.dispatch('SET_CURRENCY', this.site.currency)  //Раскомментировать, когда с сервера будет приходить валюта - currency
        if(this.site.domain==='dubai') this.$store.dispatch('SET_CURRENCY', {unit:'AED', description:'AED'}) //Заглушка - убрать, когда с сервера будет приходить валюта - currency
        else this.$store.dispatch('SET_CURRENCY', {unit:'₽', description:'RUB'})


        this.$store.dispatch('SET_CURRENT_DOMAIN', this.site.domain)
        if(this.hostName === 'https://itrielt.ru' || this.hostName === 'https://itrealt.com' || this.hostName === 'https://itarealty.com') {
          document.title = 'ITRIELT'
        }
        else if(this.hostName==='https://building.letsbot.ru' || this.hostName==='http://localhost:8080' || this.hostName==='http://localhost:8081') {
          localStorage.setItem('openSiteURL', this.host)
          localStorage.setItem('openSiteID', this.site.id)
        }
      })
    }
    // hostname = 'dana.itrielt.ru'
    // if(this.hostName === 'https://building.letsbot.ru' || this.hostName === 'http://localhost:8080' || this.hostName === 'http://localhost:8081') {
      axios.get('/api/get-list').then(res => {
        this.optionsDevelopers = res.data
      })
    // }
    this.init = false
    this.$forceUpdate()

    var self = this
    window.addEventListener('beforeinstallprompt', (e) => {
      // alert('install mounted')
      e.preventDefault();
      $('#installApp').modal('show')
      self.installPrompt = e;
    });
    if (localStorage.getItem('lang')){
      let lang=localStorage.getItem('lang')
      if(lang==='hy')this.$store.dispatch('SET_MASK_PHONE', '+999(99)99-99-99')
    }
  },
  created() {
    axios.defaults.headers.common['Token'] = this.uuid
  },
  methods: {
    startDeleteSnackTimer(){
      setTimeout(()=>{
        this.listBar.shift()
      },5*1000)
    },
    openAddToCollection(){
      this.visibleAddToCollection=true
    },
    closeAddToCollection(text){
      this.visibleAddToCollection=false
      if(text){
        this.addSnackbar({text:text, icon:'/img/service_icons/check_circle.svg', fill:'colorIconPositive'})
      }
    },
    addSnackbar(snackObject){
      this.snackbarId++
      snackObject.key=this.snackbarId
      this.listBar.push(snackObject)
      this.startDeleteSnackTimer()
    },
    addAmoButton() {
      const s = document.createElement("script");
      s.innerHTML = `(function(a,m,o,c,r,m){
        a[m]={
          id:"406464",
          hash:"727eaf325798e8cd7b153170ec2db09e92cf0d62e3254ea476e1fe2218a79400",
          locale:"ru",
          inline:true,
          setMeta:function(p)
          {
            this.params=(this.params||[]).concat([p])
          }
        };
        a[o]=a[o]||function(){(a[o].q=a[o].q||[]).push(arguments)};
        var d=a.document,
            s=d.createElement('script');
        s.async=true;
        s.id=m+'_script';
        s.src='https://gso.amocrm.ru/js/button.js';
        d.head&&d.head.appendChild(s)
      }(window,0,'amoSocialButton',0,0,'amo_social_button'));`
      document.body.appendChild(s);
    },
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    sendHeightToParent() {
      const content = document.getElementById('main-wrapper');
      const height = content.offsetHeight;
      window.parent.postMessage({ type: 'height', value: height }, "*");
    },
    changeTheme(flag){
      if(flag){
        document.body.setAttribute("data-theme", "dark");
      } else {
        document.body.removeAttribute("data-theme");
      }
    },
    toggleChangeRoleModal() {
      $('#changeRole').modal('toggle')
    },
    goToReportChat() {
      this.showChatDesktop = !this.showChatDesktop

    /*  if (window.innerWidth < 1090) {
        this.$router.push('/chat')
      }*/
      if(this.initialChat) {
        if(this.showChatDesktop) {
          this.loadAmoCRMData()
          amoSocialButton('runChatShow')
        }
        else {
          amoSocialButton('runChatHide')
        }
      }
    },
    loadAmoCRMData() {
      if(this.$store.getters.getProfile.id) {
        var userProfile = this.$store.getters.getProfile
        window.amoSocialButtonConfig = {
          onlinechat: {
            user_id: userProfile.id
          },
        };
        amo_social_button.setMeta({
          bot_params: {
            user_id: userProfile.id,
            username: [userProfile.director_lastname, userProfile.director_name, userProfile.director_patronymic].join(' '),
            phone: userProfile.phone,
            role: userProfile.role,
            domain: userProfile.domain,
            profile: userProfile.link
          }
        });
      }
    },
    getNoun(number) {
      let originalText= ''
      const one='день'
      const two='дня'
      const five='дней'
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        originalText=five;
      } else {
        n %= 10;
        if (n === 1) {
          originalText=one;
        } else if (n >= 2 && n <= 4) {
          originalText=two;
        } else originalText=five;
      }
      let fullText=''+number+' '+originalText
      let lang =localStorage.getItem('lang')
      if (lang&&lang!=='ru'){
        let translateUrl = "https://translate.googleapis.com/translate_a/single?format=text&client=gtx&sl=ru&tl=" + lang + "&dt=t&q=" + fullText;
        let translatedText = this.$root.getTranslate(translateUrl);
        return JSON.parse(translatedText)[0][0][0].split(' ')[1]
      } else return fullText.split(' ')[1]
    },
    getFormattTimerSymbols(symbol){
      if(String(symbol).length===1)  return '0'+symbol
      return symbol
    },
    getFormattedTime(time) {
      if (time <= 0) {
        clearInterval(this.intervalBanner)
        this.visibleTimer = false
      }
      this.formattedTime.days = Math.floor(time / (1000 * 60 * 60 * 24))
      this.formattedTime.hours = Math.floor(time % (1000 * 60 * 60 * 24) / (1000 * 60 * 60))
      this.formattedTime.minutes = Math.floor(time % (1000 * 60 * 60) / (1000 * 60))
      this.formattedTime.seconds = Math.floor(time % (1000 * 60) / 1000)
    },
    inIframe () {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    getTranslate(url) {
      var xmlHttp = new XMLHttpRequest();
      xmlHttp.open("GET", url, false);
      xmlHttp.send(null);
      return xmlHttp.responseText;
    },
    async redirectToProfile() {
      if (this.$router.currentRoute.path !== '/profile') {
        await this.$router.push('/profile');
        window.location.reload()
      }
    },
    closeVisibleModalCoins(){
      this.visibleModalCoins=false
    },
    answerFuncNews(idInfo){
      this.visibleCongratulationsNews=true
      axios.post('/api/edit-newsmain-answer?newsmainid='+idInfo, this.newsAnswer).then(res => {
        axios.get('/api/set-newsmain-read?newsmainid='+idInfo)
      })
    },
    textWithUrltoHrefNews(text) {
      if(text){
        var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;
        return text.replace(exp,"<a href='$1' target='_blank'>$1</a>");
      }
    },
    deleteNews(idNews){
      axios.get('/api/set-newsmain-read?newsmainid='+idNews)
      this.visibleModalNews=false
    },
    disconnectWs() {
      if(this.connection !== null) {
        this.connection.close()
        this.connection = null
        clearInterval(this.interval)
      }
    },
    connectWs() {
      this.connection = new WebSocket((`wss://chat.itrielt.ru:${this.$store.getters.getChatPort}/ws?token=${this.uuid}&user_id=${this.profile.id}`))
      this.connection.onopen = () => {
        // console.log('Connect')
        this.connection.send(
            JSON.stringify({
              type:'connect',
              data:{
                user_id: this.profile.id,
                dialog_type: 'question',
                subdomain:this.site.domain
              }
            })
        )
        this.interval = setInterval(() => {
          this.connection.send('{"type":"ping"}')
        }, 30 * 1000)
      }
      this.connection.onmessage = (res) => {
        let json = JSON.parse(res.data)
        if(!json.status) {
          /*    if(Object.keys(json).length===1){
                this.$store.dispatch('SET_MESSAGE', json.message)
                axios.get(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/dialogs/list?type=question&is_archived=false`, {
                  headers: {
                    user_id: this.profile.id
                  },
                }).then(response=>{
                  this.$store.dispatch('SET_MESSAGES', response.data.result)
                });
              }*/
          if(json.message){
            this.$store.dispatch('SET_MESSAGE', json.message)
            axios.get(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/dialogs/list?type=question&is_archived=false`, {
              headers: {
                user_id: this.profile.id
              },
            }).then(response=>{
              this.$store.dispatch('SET_MESSAGES', response.data.result)
            });
          }
          /*       if(Object.keys(json).length===3){
                   axios.get(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/dialogs/list?type=question&is_archived=false`, {
                     headers: {
                       user_id: this.profile.id
                     },
                   }).then(response=>{
                     this.$store.dispatch('SET_MESSAGES', response.data.result)
                   });
                 }*/
        }
      }
    },
    optionSelectCountry(option){
      this.selectedCountry=option
    },
    optionSelectDeveloper(option){
      this.selectedDeveloper=option
      // localStorage.setItem('openSite', option.id)
      axios.get('/api/load-account?id='+option.id).then(res => {
        this.site = res.data

        //this.$store.dispatch('SET_CURRENCY', this.site.currency)  //Раскомментировать, когда с сервера будет приходить валюта - currency
        if(this.site.domain==='dubai') this.$store.dispatch('SET_CURRENCY', {unit:'AED', description:'AED'}) //Заглушка - убрать, когда с сервера будет приходить валюта - currency
        else this.$store.dispatch('SET_CURRENCY', {unit:'₽', description:'RUB'})

        document.title = this.site.name
        if(this.hostName === 'https://itrielt.ru' || this.hostName === 'https://itrealt.com' || this.hostName === 'https://itarealty.com') {
          document.title = 'ITRIELT'
        }
        this.selectedDeveloper = {id:this.site.id, value:this.site.name}
        var hostname = this.site.domain+'.itrielt.ru'
        localStorage.removeItem('blackApp')
        if(this.hostName==='https://building.letsbot.ru' || this.hostName==='http://localhost:8080' || this.hostName==='http://localhost:8081') {
          localStorage.setItem('openSiteURL', hostname)
          localStorage.setItem('openSiteID', option.id)
          window.open('/', "_self")
        } else window.open('https://'+hostname,"_self")
      })
    },
    optionSelectRole(option){
      let oldValue=this.selectedRole
      this.selectedRole=option
      axios.post('/api/change-user', {
        newid: option.id
      }).then(res=>{
        if(res.data['status']==='success') this.getAuth()
        else if (res.data['status']==='error'){
          alert(res.data.message+'. Попробуйте снова!')
          this.selectedRole=oldValue
          let masRoles=[]
          res.data.aviable.map(el=>{
            let name=''
            if(el.director_lastname&&el.director_name) name+=el.director_lastname+' '+el.director_name
            else if(el.director_lastname) name+=el.director_lastname
            else if(el.director_name) name+=el.director_name
            let obj={"id":el.id,"role":el.role,"subrole":el.subrole,"fio":name, avatar:el.avatar}
            if(el.grouprole){
              switch (el.grouprole){
                case 'director':
                  obj.grouprole=this.$i18n.t('Руководитель')
                  break;
                case 'responsible':
                  obj.grouprole=this.$i18n.t('РукОтдела')
                  break;
                case 'user':
                  obj.grouprole=this.$i18n.t('СотрудникОтдела')
                  break;
              }
              if(el.name){
                obj.agency=el.name
              }
              if(el.groupinfo.length&&el.grouprole!=='director'){
                obj.groupinfo=el.groupinfo[0]
              }
            }
            if(el.grouprole!=='director'){

            }
            masRoles.push(obj)
          })
          masRoles.map(el=>{
            switch (el.role){
              case 'admin':
                el.role=this.$i18n.t('Администратор')
                break;
              case 'bank':
                el.role=this.$i18n.t('РедакторБанк')
                break;
              case 'tests':
                el.role=this.$i18n.t('РедакторОбучение')
                break;
              case 'bitrix':
                el.role=this.$i18n.t('Менеджер')
                break;
              case 'client':
                el.role=this.$i18n.t('Клиент')
                break;
              case 'marketing':
                el.role=this.$i18n.t('РедакторМаркетинг')
                break;
              case 'sales':
                el.role=this.$i18n.t('РедакторПродажи')
                break;
              case 'tech':
                el.role=this.$i18n.t('РедакторТех')
                break;
              case 'user':
                el.role=this.$i18n.t('Агент')
                if(el.subrole ==='leader') el.role = this.$i18n.t('Агентство')
                break;
              case 'agency':
                el.role=this.$i18n.t('Агентство')
                break;
              case 'partner':
                el.role=this.$i18n.t('Партнер')
                break;
              case 'partners':
                el.role=this.$i18n.t('Партнер')
                break;
            }
          })
          this.$store.dispatch('SET_ROLES', masRoles)
          if(this.roles){
            if(this.roles.length>1){
              this.optionsRole=this.roles
              this.optionsRole.map(el=>{
                if(el.id===res.data.profile.id) this.selectedRole=el
              })
            }
            else {
              this.optionsRole=this.roles
              this.selectedRole=this.roles[0]
            }
          }
          this.$forceUpdate()
        }

        this.$router.push('/')
      })
    },
    utf8_to_b64(str) {
      return window.btoa(unescape(encodeURIComponent(str)));
    },
    async showLinkCard() {
      let hash
      let title='IT Риэлт'
      let text='Моя визитка'
      axios.get('/api/get-hash').then(res => {
        hash=res.data.hash
        this.shareData = {
          title: title,
          text: text,
          url: this.hostName+'/business-card/'+hash
        }
        $('#canvasCard').html('')
        this.qr = new QRCodeStyling({
          width: 2048,
          height: 2048,
          type: "canvas",
          data: this.shareData.url,
          image: this.hostName+'/itrieltQr.svg',
          dotsOptions: {
            color: "#284F9A",
            type: "extra-rounded"
          },
          cornersSquareOptions: {
            type: 'extra-rounded'
          },
          cornersDotOptions: {
            type: 'dot'
          },
          backgroundOptions: {
            color: "#fff",
          },
          imageOptions: {
            crossOrigin: "anonymous",
            margin: 20
          }
        });
        this.qr.append(document.getElementById('canvasCard'));
        this.visibleModalCard=true
      })
    },
    async showLink() {
      var type
      var url
      var title
      var text
      if(this.role === 'bitrix') {
        title='IT Риэлт'
        text='Я продаю тут'
        type="curatorLink"
        url='curator'
      }
      else if(this.role === 'user') {
        title='IT Риэлт'
        text='Я продаю тут'
        type="ambassadorLink"
        url='ambassador'
      } else {
        type="partnerLink"
        url='partner'
        title='ITRIELT'
        text= 'Посмотрите мое предложение'
      }
      var hash = this.utf8_to_b64(JSON.stringify({
        "type": type,
        "id": this.profile.id
      }))
      this.shareData = {
        title: title,
        text: text,
        url: this.hostName+'/'+url+'/'+hash
      }
      try {
        await navigator.share(this.shareData)
      } catch(err) {

      }
      this.visibleMainShareModal = true
      $('#mainShareQr').html('')

      if (url==='partner'){
        this.qr = new QRCodeStyling({
          width: 2048,
          height: 2048,
          type: "canvas",
          data: this.shareData.url,
          dotsOptions: {
            color: "#102D4D",
            type: "extra-rounded"
          },
          cornersSquareOptions: {
            type: 'extra-rounded'
          },
          cornersDotOptions: {
            type: 'dot'
          },
          backgroundOptions: {
            color: "#fff",
          },
          imageOptions: {
            crossOrigin: "anonymous",
            margin: 20
          }
        });
      } else {
        this.qr = new QRCodeStyling({
          width: 2048,
          height: 2048,
          type: "canvas",
          data: this.shareData.url,
          image: this.hostName+'/forQR.svg',
          dotsOptions: {
            color: "#102D4D",
            type: "extra-rounded"
          },
          cornersSquareOptions: {
            type: 'extra-rounded'
          },
          cornersDotOptions: {
            type: 'dot'
          },
          backgroundOptions: {
            color: "#fff",
          },
          imageOptions: {
            crossOrigin: "anonymous",
            margin: 20
          }
        });
      }
      setTimeout(()=>{
        this.qr.append(document.getElementById('mainShareQr'));
      },70)

    },
    downloadQr() {
      this.qr.download({ name: "qr", extension: "png" });
    },
    closeInfoCurator(){
      this.areShowInfoCurator=false
    },
    async getAuth () {
      const res = await axios.get('/api/get-auth')
      this.menu = res.data.menu
      this.agreement=res.data.profile.agreement
      var token = res.data.token
      if(token !== this.uuid)  {
        axios.defaults.headers.common['Token'] = token
        this.$store.dispatch('SET_UUID', token)
        // location.reload()
      }
      let masRoles=[]
      res.data.roles.map(el=>{
        let name=''
        if(el.director_lastname&&el.director_name) name+=el.director_lastname+' '+el.director_name
        else if(el.director_lastname) name+=el.director_lastname
        else if(el.director_name) name+=el.director_name
        let obj={"id":el.id,"role":el.role,"subrole":el.subrole,"fio":name, avatar:el.avatar,org_name: el.org_name}
        if(el.grouprole){
          switch (el.grouprole){
            case 'director':
              obj.grouprole=this.$i18n.t('Руководитель')
              break;
            case 'responsible':
              obj.grouprole=this.$i18n.t('РукОтдела')
              break;
            case 'user':
              obj.grouprole=this.$i18n.t('СотрудникОтдела')
              break;
          }
          if(el.name){
            obj.agency=el.name
          }
          if(el.groupinfo.length&&el.grouprole!=='director'){
            obj.groupinfo=el.groupinfo[0]
          }
        }
        if(el.grouprole!=='director'){

        }
        masRoles.push(obj)
      })
      masRoles.map(el=>{
        switch (el.role){
          case 'admin':
            el.role=this.$i18n.t('Администратор')
            break;
          case 'bank':
            el.role=this.$i18n.t('РедакторБанк')
            break;
          case 'tests':
            el.role=this.$i18n.t('РедакторОбучение')
            break;
          case 'bitrix':
            el.role=this.$i18n.t('Менеджер')
            break;
          case 'client':
            el.role=this.$i18n.t('Клиент')
            break;
          case 'marketing':
            el.role=this.$i18n.t('РедакторМаркетинг')
            break;
          case 'sales':
            el.role=this.$i18n.t('РедакторПродажи')
            break;
          case 'tech':
            el.role=this.$i18n.t('РедакторТех')
            break;
          case 'user':
            el.role=this.$i18n.t('Агент')
            if(el.subrole ==='leader') el.role = this.$i18n.t('Агентство')
            break;
          case 'agency':
            el.role=this.$i18n.t('Агентство')
            break;
          case 'partner':
            el.role=this.$i18n.t('Партнер')
            break;
          case 'partners':
            el.role=this.$i18n.t('Партнер')
            break;
        }
      })
      this.$store.dispatch('SET_ROLES', masRoles)
      if(this.roles){
        if(this.roles.length>1){
          this.optionsRole=this.roles
          this.optionsRole.map(el=>{
            if(el.id===res.data.profile.id) this.selectedRole=el
          })
        }
        else {
          this.optionsRole=this.roles
          this.selectedRole=this.roles[0]
        }
      }
      if(this.roles){
        if(this.roles.length>1){
          this.optionsRole=this.roles
          this.optionsRole.map(el=>{
            if(el.id===res.data.profile.id) this.selectedRole=el
          })
        }
        else {
          this.optionsRole=this.roles
          this.selectedRole=this.roles[0]
        }
      }
      this.$store.dispatch('SET_ROLE', res.data.role)
      this.$store.dispatch('SET_PROFILE', Object.assign({}, res.data.profile, {menu: this.menu}))
      if(res.data.profile?.id) {
        // var userProfile = res.data.profile
        // window.amoSocialButtonConfig = {
        //   onlinechat: {
        //     user_id: userProfile.id
        //   },
        // };
        // amo_social_button.setMeta({
        //   bot_params: {
        //     user_id: userProfile.id,
        //     username: [userProfile.director_lastname, userProfile.director_name, userProfile.director_patronymic].join(' '),
        //     phone: userProfile.phone,
        //     role: userProfile.role,
        //     domain: userProfile.domain,
        //     profile: userProfile.link
        //   }
        // });
      }
      if(this.role === 'user' && this.site.domain === 'ava')this.loadHistory()
      if(res.data.role==='user'){
        axios.get('/api/get-newsmain-all-last?status=actual').then((res) => {
          this.listNews=res.data
          if(this.listNews.length){
            if(this.listNews[0].picture)this.visibleModalNews=true
            else this.visibleModalNews=false
          }
          else this.visibleModalNews=false
        })
      }
      $('body').css('display', 'unset')
      if(this.$route.name !=='default-pdf'&&this.$route.name !=='mini-pdf'&&!this.initialChat){
        setInterval(()=>{
          let chatIndicatorElement = document.getElementsByClassName(" amo-button-greeting-badge")[0];
          if(chatIndicatorElement){
            let chatIndicatorText = chatIndicatorElement.textContent || chatIndicatorElement.innerText;
            if(getComputedStyle(chatIndicatorElement).display === 'none')this.chatIndicatorValue=0
            else if(chatIndicatorText)this.chatIndicatorValue=Number(chatIndicatorText)
          }
        },1000)
        this.addAmoButton()
        amoSocialButton('onChatHide', ()=>{
          this.showChatDesktop=false
          amoSocialButton('runChatHide')
          // при открытии закрытие чата
        });

        amoSocialButton('onChatReady',()=>{
          // чат проинициализирован,
          // можно с ним работать через JavaScript API
          // amoSocialButton('runChatShow');
        });

        amoSocialButton('onButtonClick', ()=>{
          console.log('onButtonClick')
          // при клике на кнопку
          // входящие параметры:
          // код сервиса
          // url ссылки, по которой кликнули
        });

        amoSocialButton('onConversationsChange',(conversations)=>{
          if (this.showChatDesktop) {
            this.loadAmoCRMData()
            amoSocialButton('runChatShow')
          }
          this.initialChat=true
        });
      }
      if (this.$route.meta.needAuth  && this.role == 'unknown') {
        window.open('/auth',"_self")
      }
      if (this.$route.name === 'auth' && this.$route.params.hash !== undefined) this.$cookies.set("company_referer", this.$route.params.hash)
      this.setMenu()
      this.$store.dispatch('LOAD_RECENT')
      this.init = true
      /*        if(this.role!=='unknown'){
                axios.get(`https://chat.itrielt.ru:${this.$store.getters.getChatPort}/dialogs/list?type=question&is_archived=false`, {
                  headers: {
                    user_id: this.profile.id
                  },
                }).then(response=>{
                  this.$store.dispatch('SET_MESSAGES', response.data.result)
                  this.disconnectWs()
                  this.connectWs()
                });
              }*/
    },
    loadHistory(){
      axios.get('/api/get-loot-user-check').then(response => {
        this.history = response.data.reverse()
        this.history.map(el=>{
          el.type='plus'
        })
      }).then(()=>{
        axios.get('/api/get-buys-loot-check').then(response => {
          const dataMinus=response.data.reverse()
          dataMinus.map(el=>{
            el.type='minus'
          })
          this.history.push(...dataMinus)
          this.history=this.history.sort((a, b) => a.created_at > b.created_at ? 1 : -1).reverse();
        })
      })
    },
    /*closeInstallApplication(){
      this.$store.commit('SET_VISIBLE_INSTALL', 'false')
    },*/
    /*async installApplication() {
      $('#installApp').modal('hide')
      this.installPrompt.prompt();
      // Find out whether the user confirmed the installation or not
      const { outcome } = await this.installPrompt.userChoice;
      // The deferredPrompt can only be used once.
      this.installPrompt = null;
      // Act on the user's choice
      if (outcome === 'accepted') {
        // console.log('User accepted the install prompt.');
      } else if (outcome === 'dismissed') {
        // console.log('User dismissed the install prompt');
      }
    },*/
    coinFormat(value) {
      if(!value) return '0'
      var formatter = new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 0
      })
      return formatter.format((value || 0)) || 0
    },
    priceFormat(value) {
      if(!value) return ''
      var formatter = new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 0
      })
      return formatter.format((value || 0)) || 0
    },
    generateUUID() {
      var d = new Date().getTime();
      var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;
        if(d > 0){
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
        } else {
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    },
    newLead () {
      this.$router.push('/deals/create')
    },
    goBack () {
      this.$router.go(-1)
    },
    createCompilation () {
      var ids = this.compl
      if(ids.length == 0 && this.object.id) {
        $('#createComplSingle').modal('show')
      } else {
        $('#createCompl').modal('show')
      }
      // console.log('Создаём')
    },
    sendCompilation () {
      if(this.compName){
        var ids = this.compl
        if(ids.length == 0 && this.object.id) {
          ids = [this.object.id]
        }
        axios.post('/api/send-compl', {
          uuid: this.uuid,
          name: this.compName,
          ids: ids
        })
        this.$store.commit('SET_COMPL', [])
        this.compName = null
        this.response = this.$i18n.t('СпасибоВашаПодборкаСохраненаМожетеПерейтиВРазделПодборкиДляРаботыСНей')
        $('#createComplSingle').modal('hide')
        $('#createCompl').modal('hide')
        /*this.visibleResponseModal=true*/
        $('#responseModal').modal('show')
        this.errorInfoTitle=false
      }else this.errorInfoTitle=true
    },
    clearResponse () {
     /* this.visibleResponseModal=false*/
      this.response = null
    },
    setRole (id) {
      this.$store.dispatch('SET_ROLE', id)
      if(id === 'unknown') {
        this.$store.dispatch('SET_ROLES', null)
        this.$store.dispatch('SET_UUID', 'unknown')
      }
      if(id === 'founder') this.$router.push('/deals')
      else if(id === 'admin') this.$router.push('/stats')
      else if(id === 'unknown') location.reload()
      else this.$router.push('/')
    },
    async shareObjects() {
      var ids = this.compl
      if(ids.length == 0 && this.object.id) {
        ids = [this.object.id]
      }

      let calc_result = {}

      if (localStorage.getItem('externalPlan')) {
        calc_result = {
          plan: await this.loadPlan(localStorage.getItem('externalPlan'))
        }
      }

      axios.post('/api/create-link', {
        uuid: this.uuid,
        ids: ids,
        comp_id:this.objectBookMark.id,
        calc_result
      }).then(async response => {
        this.shareData = {
          title: 'ITRIELT',
          text: this.$i18n.t('ПосмотриНаЭтиЧудесныеОбъявления'),
          url: this.hostName+'/visit/'+response.data.hash
        }
        try {
          await navigator.share(this.shareData)
        } catch(err) {}
        this.visibleMainShareModal = true
        $('#mainShareQr').html('')

        this.qr = new QRCodeStyling({
          width: 2048,
          height: 2048,
          type: "canvas",
          data: this.shareData.url,
          image: this.hostName+'/forQR.svg',
          dotsOptions: {
            color: "#102D4D",
            type: "rounded"
          },
          backgroundOptions: {
            color: "#fff",
          },
          imageOptions: {
            crossOrigin: "anonymous",
            margin: 20
          }
        });

        setTimeout(()=>{
          this.qr.append(document.getElementById('mainShareQr'));
        },70)
      })
    },
    async shareUrl(){
      try {
        await navigator.share(this.shareData)
      } catch(err) {}
    },
    copyUrlCard() {
      var inp = document.createElement('input')
      inp.value = this.shareData.url
      document.body.appendChild(inp)
      inp.select()

      if (document.execCommand('copy')) {
        this.response = this.$i18n.t('СсылкаУспешноСкопирована')
      } else {
        this.response = this.$i18n.t('ПриКопированииПроизошлаОшибка')
      }
      $('#responseModal').modal('show')
     /* this.visibleResponseModal=true*/

      document.body.removeChild(inp)
    },
    async loadPlan(base64) {
      let formData = new FormData()
      formData.set('file[name]', 'plan.png')
      formData.set('file[data]', base64)

      const {data} = await axios.post('https://cdn.itrielt.ru/upload/file', formData)

      if (data.status === 'success') {
        return data.url
      }

      return null
    },
    copyUrl() {
      var Url = document.getElementById('mainShareUrl'); /*GET vuejs el reference here (via $ref) but how?*/
      Url.select();
      document.execCommand("copy");

      this.response = this.$i18n.t('СсылкаУспешноСкопирована')
      $('#responseModal').modal('show')
      /*this.visibleResponseModal=true*/
    },
    setMenu() {
      this.menus = []
      var firstPoint //переменная для мобилки, где роль bitrix
      var secondPoint //переменная для мобилки, где роль bitrix
      var thirdPoint //переменная для мобилки, где роль bitrix
      this.$router.options.routes.forEach(route => {
        if(this.menu && this.menu[route.name]) {

          if(route.meta.label !== undefined) {
            if(route.path==='/events'&&this.role === 'bitrix'){
              firstPoint={
                link: route.path,
                icon: route.meta.icon || null,
                name: route.name,
                label: this.$i18n.t(route.meta.label || ''),
                select: false
              }
            } else if(route.path==='/catalog'&&this.role === 'bitrix'){
              secondPoint={
                link: route.path,
                icon: route.meta.icon || null,
                name: route.name,
                label: this.$i18n.t(route.meta.label || ''),
                select: false
              }
            }else if(route.path==='/board'&&this.role === 'bitrix'){
              thirdPoint={
                link: route.path,
                icon: route.meta.icon || null,
                name: route.name,
                label: this.$i18n.t(route.meta.label || ''),
                select: false
              }
            } else {
              this.menus.push({
                link: route.path,
                icon: route.meta.icon || null,
                name: route.name,
                label: this.$i18n.t(route.meta.label || ''),
                select: false
              })
            }
          }
          this.$forceUpdate()
        }
      })
      if(this.role === 'bitrix'){
        let firstValues=[firstPoint,secondPoint,thirdPoint]
        firstValues=firstValues.filter(el=>el)
        this.menus = firstValues.concat(this.menus)
        this.$forceUpdate()
      }
      if(this.role == 'user') {
        /*|| !this.profile.city*/
        if(this.profile.responsible === null && this.site.domain === 'ava' || !this.profile.director_lastname || !this.profile.director_name  || !this.profile.city){
          this.menus = [
            {
              link: '/profile',
              icon: 'User',
              name: 'profile',
              label: this.$i18n.t('Профиль'),
              select: false
            }
          ]
          this.$forceUpdate()
        }
      }
      if(this.hostName==='https://building.letsbot.ru' || this.hostName==='http://localhost:8080' || this.hostName==='http://localhost:8081') {
        if(this.role == 'admin') {
          this.menus = [{
            link: '/subdomains',
            icon: 'subdomains',
            name: 'subdomains',
            label: this.$i18n.t('АккаунтыIT'),
            select: false
          }].concat(this.menus)
          this.$forceUpdate()
        }
      }
      this.$forceUpdate()
    }
  },
  watch: {
    blackTheme(newValue){
      this.changeTheme(newValue)
    },
    site:{
      handler(after, before){
        if(after.palette && typeof after.palette === 'object' && Object.keys(after.palette).length) setPalette(after.palette)
       /* if(after.black_theme&&!localStorage.getItem('blackApp')){
          this.$store.commit('SET_BLACK_THEME',after.black_theme)
        }              Раскомментировать в будущем, когда будет приходить тема с сервера*/
        switch (after.domain){
          case 'afi-development':
            const obj = this.$i18n.messages.ru
            obj['Литер']='Корпус'
            this.$i18n.setLocaleMessage('ru', obj)
            break;
          case 'tochno':
            document.documentElement.style.setProperty('--defaultFontFamily', 'Pangram');
            if(!localStorage.getItem('blackApp'))this.$store.commit('SET_BLACK_THEME',true)
            break;
          case 'ava':
            document.documentElement.style.setProperty('--defaultFontFamily', 'Averta CY');

            const observer = new MutationObserver(this.sendHeightToParent);
            const config = { attributes: true, childList: true, subtree: true };
            const targetNode = document.getElementById('main-wrapper');
            observer.observe(targetNode, config);
            window.addEventListener('load', this.sendHeightToParent);

            break;
        }
        this.changeTheme(this.blackTheme)
    /*      const randomIndex = Math.floor(Math.random() * (this.bannerTextList.length - 1)); // генерируем случайный индекс в допустимом диапазоне
          this.bannerText = this.bannerTextList[randomIndex]; // извлекаем значение под случайным индексом*/
        /*let datefinish=new Date(`1 Dec 2023 00:00:00`)

        let dateNext=new Date(`27 Dec 2023 00:00:00`)*/

/*        let datefinish=new Date(`1 Jan 2024 00:00:00`)

        let dateNext=new Date(`7 Jan 2024 00:00:00`)
        if(Date.now()>dateNext){
          this.nextBanner=true
          this.visibleTimer = false
        } else {
          if (datefinish - Date.now() > 0) {
            this.intervalBanner=setInterval(() => {
              let timeNow = Date.now()
              let timer = datefinish - timeNow
              this.getFormattedTime(timer)
            }, 1000)
          } else {
            clearInterval(this.intervalBanner)
            this.visibleTimer = false
          }
        }*/
        /*let datefinish='2024-01-01 00:00:00'*/
        /*  let datefinish='2023-12-15 16:10:00'*/

          /*datefinish= datefinish.getTime() + Math.abs(datefinish.getTimezoneOffset())*/


      },
      deep:true
    },
    messages(newValue){
      if(newValue){
        if(newValue.dialogs){
          let counter=0
          newValue.dialogs.map(el=>{
            counter+=el.count_unread
          })
          newValue.unread=counter
          this.$store.dispatch('SET_MESSAGES', newValue)
        }
      }
    },
    role () {
      this.setMenu()
    },
    $route () {
      setTimeout(()=> {
        window.scrollTo({top: 0})
        $('.modal-backdrop.show').remove()
      }, 1)
      /*this.$store.commit('SET_COMPL', [])*/
    }
  }
})
