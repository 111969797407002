/**
 * Обнуляет все свойства переданного объекта, делая его полям значение null
 *
 * @param {Object} obj
 */
export function resetObjectProperties(obj) {
    for (let key in obj) {
        if (Array.isArray(obj[key])) {
            obj[key] = [];
        } else if (typeof obj[key] === "object") {
            resetObjectProperties(obj[key]);
        } else {
            obj[key] = null;
        }
    }
}

export function isNumber(evt) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;

    if ((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) {
        evt.preventDefault();
    } else {
        return true;
    }
}

/**
 * Меняет цвет текста заданного элемента в зависимости от яркости фона УДАЛИТЬ ОТСЮДА!!!
 * @param {string} hex - Цвет фона
 * @returns {string} - Новый цвет текста в HEX
 */
export function changeTextColor(hex) {
    const threshold = 150
    const rgbColor = hexToRgb(hex)
    const brightness = (rgbColor.r * 299 + rgbColor.g * 587 + rgbColor.b * 114) / 1000

    if (brightness < threshold) {
        return '#fff'
    }

    return '#000'
}

/**
 * Функция определения координат прямоугольника по координатам его диагонали.
 *
 * @export
 * @param {{ x: number, y: number }} a Координаты первой вершины диагонали
 * @param {{ x: number, y: number }} b Координаты второй вершины диагонали
 * @returns {{ a: { x: number, y: number }, b: { x: number, y: number }, c: { x: number, y: number }, d: { x: number, y: number }}}
 */
export function findRectangleCoordsByDiagonalCoords(a, b) {
    const centerX = (a.x + b.x) / 2;
    const centerY = (a.y + b.y) / 2;

    const halfWidth = Math.abs(b.x - a.x) / 2;
    const halfHeight = Math.abs(b.y - a.y) / 2;

    const c = {
        x: centerX - halfHeight,
        y: centerY + halfWidth
    };
    const d = {
        x: centerX + halfHeight,
        y: centerY - halfWidth
    };

    return {a, b, c, d}
}

/**
 * Функция для склонения числительных
 *
 * @param {number} number
 * @param {Array.<string>} titles
 *
 * @returns {string}
 */
export function declineNumber(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    const index = (number % 100 > 4 && number % 100 < 20)
        ? 2
        : cases[(number % 10 < 5) ? number % 10 : 5];
    return titles[index];
}

/**
 * Функция для переключения элементов в массиве. Если значение присутствует в массиве, оно удаляется,
 * а если отсутствует, то добавляется.
 *
 * @param {Array} array - Массив, в котором необходимо переключить элемент.
 * @param {*} value - Значение элемента, который нужно переключить.
 * @returns {void}
 */
export function toggleElementInArray(array, value) {
    let index = array.indexOf(value);

    if (index === -1) {
        array.push(value);
    } else {
        array.splice(index, 1);
    }
}

/**
 * Удаляет первое вхождение указанной строки из массива.
 * @param {string[]} array - Массив, из которого требуется удалить указанное значение.
 * @param {string|string[]} value - Значение, которое требуется удалить из массива.
 */
export function arrRemoveByString(array, value) {
    function removeFromArray(array, value) {
        let index = array.indexOf(value);

        if (index !== -1) {
            array.splice(index, 1);
        }
    }

    if (Array.isArray(value)) {
        value.forEach((item) => {
            removeFromArray(array, item)
        })
    } else {
        removeFromArray(array, value)
    }

}

export function getCityFromGeocoder(res) {
    const area = res.metaDataProperty.GeocoderMetaData.AddressDetails.Country.AdministrativeArea

    if (area.SubAdministrativeArea.hasOwnProperty('Locality') && area.SubAdministrativeArea.Locality.hasOwnProperty('LocalityName')) {
        return area.SubAdministrativeArea.Locality.LocalityName
    }
    return area.AdministrativeAreaName
}

/**
 * Преобразование HEX в RGB !!!УДАЛИТЬ ОТСЮДВ
 *
 * @param {string} hex
 * @returns {{r:number, g:number, b:number}}
 */
export function hexToRgb(hex) {
    hex = hex.slice(1)
    const r = parseInt(hex.slice(0, 2), 16)
    const g = parseInt(hex.slice(2, 4), 16)
    const b = parseInt(hex.slice(4, 6), 16)

    return {r, g, b}
}

export function calculateYearsAndMonths(months) {
    let years = Math.floor(months / 12);
    let remainingMonths = months % 12;

    return { years, remainingMonths };
}

/**
 * Проверка, является ли значение пустым
 * @param {*} value - проверяемое значение
 * @returns {boolean} - возвращает true, если значение пустое, иначе false
 */
export function isEmpty(value) {
    if (Array.isArray(value)) {
        return value.length === 0
    }
    return value === null || value === '' || value === undefined
}

/**
 * Преобразует текст, содержащий URL, в HTML-формат, где каждый URL обернут в элемент <a>
 *
 * @param {string} text
 * @returns {string}
 */
export function textWithUrltoHref(text) {
    if (text) {
        let regex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

        return text.replace(regex, function (url) {
            return '<a href="' + url + '" target="_blank">' + url + '</a>';
        });

    }

    return text;
}

/**
 * Генерирует случайный номер телефона в формате +7 (XXX) XXX-XXXX.
 * @returns {string}
 */
export function generateRandomPhone() {
    let areaCode = Math.floor(Math.random() * 800) + 200;
    let prefix = Math.floor(Math.random() * 900) + 100;
    let line = Math.floor(Math.random() * 9000) + 1000;
    return "+7 (" + areaCode + ") " + prefix + "-" + line;
}

/**
 * Преобразует координаты с учетом соотношения сторон
 * @param {{width: *, height: *}} oldBox - Старые ширина и высота
 * @param {{width: *, height: *, left:*, top:*}} rect - Новые ширина и высота
 * @param {{x:*, y:*}} coords - Исходные координаты для преобразования
 * @returns {{x:number, y:number}} - Преобразованные координаты
 */
export function getCoordsByAspect(oldBox, rect, coords) {
    const oldWidth = parseFloat(oldBox.width)
    const oldHeight = parseFloat(oldBox.height)

    const newWidth = parseFloat(rect.width)
    const newHeight = parseFloat(rect.height)

    const oldX = parseFloat(coords.x)
    const oldY = parseFloat(coords.y)

    const scaleX = oldWidth / newWidth
    const scaleY = oldHeight / newHeight

    const x = convertCoords(oldX, rect.left, scaleX);
    const y = convertCoords(oldY, rect.top, scaleY);

    return {x, y}
}

/**
 * Получает viewbox обрезанного полигона
 * @param {Array} croppedVertices - Массив координат вершин полигона
 * @returns {{x:number, y:number, width:number, height:number, minY:number, maxY:number, minX:number, maxX:number}}
 */
export function getViewboxOfPolygon(croppedVertices) {
    let minX = Infinity;
    let maxX = -Infinity;
    let minY = Infinity;
    let maxY = -Infinity;

    croppedVertices.forEach((vertex) => {
        minX = Math.min(minX, vertex.x);
        maxX = Math.max(maxX, vertex.x);
        minY = Math.min(minY, vertex.y);
        maxY = Math.max(maxY, vertex.y);
    })

    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY,
        maxY,
        minY,
        maxX,
        minX
    }
}

/**
 * Находит центр тяжести треугольника для трех заданных точек.
 *
 * @param {{x: number, y: number}} point1 - Первая вершина треугольника.
 * @param {{x: number, y: number}} point2 - Вторая вершина треугольника.
 * @param {{x: number, y: number}} point3 - Третья вершина треугольника.
 * @returns {{x: number, y: number}} - Координаты центра тяжести треугольника.
 */
export function findTriangleCenter(point1, point2, point3) {
    let centerX = (point1.x + point2.x + point3.x) / 3;
    let centerY = (point1.y + point2.y + point3.y) / 3;

    return {x: centerX, y: centerY};
}

/**
 * Находит прямоугольник с наибольшей диагональю внутри многоугольника.
 *
 * @param {Array<{x: number, y: number}>} vertices - Вершины многоугольника.
 * @returns {Object|null} - Результирующий прямоугольник с координатами вершин и размерами, или null, если такой прямоугольник найти невозможно.
 */
export function findLongestDiagonal(vertices) {
    let resultRectangle = null
    let largestWidth = 0

    for (let i = 0; i < vertices.length; i++) {
        const vertex1 = vertices[i];

        for (let j = (i + 1); j < vertices.length; j++) {
            const vertex2 = vertices[j];
            let stop = false
            const line = linearInterpolation(vertex1, vertex2, 50)

            line.forEach((vertex) => {
                if (!isVertexInsidePolygon(vertex, vertices)) {
                    stop = true
                }
            })

            if (stop) {
                continue
            }

            const rectangle = [
                {x: vertex1.x, y: vertex1.y},
                {x: vertex2.x, y: vertex1.y},
                {x: vertex2.x, y: vertex2.y},
                {x: vertex1.x, y: vertex2.y}
            ];

            const viewbox = getViewboxOfPolygon(rectangle)

            if (largestWidth < viewbox.width) {
                largestWidth = viewbox.width
                resultRectangle = viewbox
            }
        }
    }

    return resultRectangle;
}

/**
 * Перемещает полигон в начало координат
 * @param {Array<{x,y}>} polygonVerticles
 * @return {{offsetX: number, polygonVerticles: Array<{x, y}>, offsetY: number}}
 */
export function movePolygonToOrigin(polygonVerticles) {
    let minX = Infinity
    let minY = Infinity

    for(let i = 0; i < polygonVerticles.length; i++) {
        if(polygonVerticles[i].x < minX) {
            minX = polygonVerticles[i].x
        }
        if(polygonVerticles[i].y < minY) {
            minY = polygonVerticles[i].y
        }
    }

    for(let i = 0; i < polygonVerticles.length; i++) {
        polygonVerticles[i].x -= minX
        polygonVerticles[i].y -= minY
    }

    return {polygonVerticles, offsetX: minX, offsetY: minY}
}

/**
 * Вычисляет длину вектора на плоскости.
 *
 * @param {number} x1 - Координата X начальной точки.
 * @param {number} y1 - Координата Y начальной точки.
 * @param {number} x2 - Координата X конечной точки.
 * @param {number} y2 - Координата Y конечной точки.
 * @returns {number} Длина вектора.
 * @throws {Error} Ошибка, если один из аргументов не является числом.
 */
export function vectorLength(x1, y1, x2, y2) {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    const lengthSquared = deltaX ** 2 + deltaY ** 2;
    const length = Math.sqrt(lengthSquared);

    if (isNaN(length)) {
        throw new Error("Один из аргументов не является числом.");
    }

    return length;
}

/**
 Находит все указанные слова в тексте
 @param {string} text
 @param {string} searchText
 @returns {boolean}
 */
export function findWordsInText(text, searchText) {
    const lowerText = text.toLowerCase();
    const lowerSearchText = searchText.toLowerCase();
    const searchWords = lowerSearchText.split(' ');
    return searchWords.every(word => lowerText.includes(word));
}

/**
 * Делает первую букву в строке заглавной
 * @param str
 * @return {*|string}
 */
export function ucFirst(str) {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
}

function isVertexInsidePolygon(vertex, polygon) {
    const x = vertex.x
    const y = vertex.y
    let inside = false

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].x
        const yi = polygon[i].y
        const xj = polygon[j].x
        const yj = polygon[j].y

        const intersect = (yi > y) !== (yj > y) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi)

        if (intersect) {
            inside = !inside
        }

        if (x === xi && y === yi) {
            return true
        }
    }

    return inside
}

function linearInterpolation(startPoint, endPoint, numPoints) {
    const points = [];

    // Вычисляем шаг для интерполяции
    const stepSize = 1 / (numPoints - 1);

    // Интерполируем каждую точку на отрезке
    for (let i = 0; i < numPoints; i++) {
        const t = i * stepSize;
        const x = startPoint.x * (1 - t) + endPoint.x * t;
        const y = startPoint.y * (1 - t) + endPoint.y * t;
        points.push({x, y});
    }

    return points;
}

function convertCoords(value, start, scale) {
    return start === 0 ? (value - start) / scale : (value - start) * scale;
}

/**
 * @param {[Array, Array]} bounds
 * @param {YMap} map
 * @param {Boolean} inscribe
 * @param {Boolean} floor
 * @returns {Number}
 */
export function getScale(bounds, map, inscribe, floor) {
    if (typeof inscribe === "undefined") {
        inscribe = true;
    }
    if (typeof floor === "undefined") {
        floor = false;
    }
    let pixelBounds = toGlobalPixelBounds(bounds, 0);
    // 1e-10 чтобы не было деления на 0
    let deltaX = Math.max(Math.abs(pixelBounds[1][0] - pixelBounds[0][0]), 1e-10);
    let deltaY = Math.max(Math.abs(pixelBounds[1][1] - pixelBounds[0][1]), 1e-10);
    let logX = Math.log(map.size.x / deltaX) * Math.LOG2E;
    let logY = Math.log(map.size.y / deltaY) * Math.LOG2E;
    let result = Math.min(Math.max(0, inscribe ? Math.min(logX, logY) : Math.max(logX, logY)), map.zoomRange.max);
    return floor ? Math.floor(result + 1e-10) : result;
}

function toGlobalPixelBounds(geoBounds, zoom) {
    if (typeof zoom === "undefined") {
        zoom = 0;
    }

    let lowerCorner = toGlobalPixels(geoBounds[0], zoom);
    let upperCorner = toGlobalPixels(geoBounds[1], zoom);
    let projectionCycled = [true, false];
    let worldSize = calculateWorldSize(zoom);
    let result = [lowerCorner.slice(), upperCorner.slice()];
    if (lowerCorner[0] > upperCorner[0]) {
        if (projectionCycled[0]) {
            result[0][0] = lowerCorner[0];
            result[1][0] = upperCorner[0] + worldSize;
        } else {
            result[0][0] = upperCorner[0];
            result[1][0] = lowerCorner[0];
        }
    }
    if (lowerCorner[1] > upperCorner[1]) {
        if (projectionCycled[1]) {
            result[0][1] = lowerCorner[1];
            result[1][1] = upperCorner[1] + worldSize;
        } else {
            result[0][1] = upperCorner[1];
            result[1][1] = lowerCorner[1];
        }
    }
    return result;
}

function toGlobalPixels(point, zoom) {
    let radius = 6378137;
    let equator = 2 * Math.PI * radius;
    let subequator = 1 / equator;
    let pixelsPerMeter = 256 * subequator;
    let halfEquator = equator / 2;
    let currentZoom = 0;

    if (zoom != currentZoom) {
        pixelsPerMeter = Math.pow(2, zoom + 8) * subequator;
        currentZoom = zoom;
    }

    let mercatorCoords = geoToMercator(point);
    return [
        (halfEquator + mercatorCoords[0]) * pixelsPerMeter,
        (halfEquator - mercatorCoords[1]) * pixelsPerMeter
    ];
}

function geoToMercator(geo) {
    return [
        longitudeToX(geo[0]),
        latitudeToY(geo[1])
    ];
}
;

function longitudeToX(lng) {
    let radius = 6378137;
    let c_pi180 = Math.PI / 180;
    let longitude = cycleRestrict(lng * c_pi180, -Math.PI, Math.PI);
    return radius * longitude;
}

function latitudeToY(lat) {
    let radius = 6378137;
    let e = 0.0818191908426;
    let c_pi180 = Math.PI / 180;
    let c_180pi = 180 / Math.PI;
    let epsilon = 1e-10;
    let latitude = restrict(lat, -90 + epsilon, 90 - epsilon) * c_pi180;
    let esinLat = e * Math.sin(latitude);
    let tan_temp = Math.tan(Math.PI * 0.25 + latitude * 0.5);
    let pow_temp = Math.pow(Math.tan(Math.PI * 0.25 + Math.asin(esinLat) * 0.5), e);
    let U = tan_temp / pow_temp;

    return radius * Math.log(U);
}

function restrict(value, min, max) {
    return Math.max(Math.min(value, max), min);
}
function cycleRestrict(value, min, max) {
    if (value == Number.POSITIVE_INFINITY) {
        return max;
    } else if (value == Number.NEGATIVE_INFINITY) {
        return min;
    }
    return value - Math.floor((value - min) / (max - min)) * (max - min);
}
function calculateWorldSize(zoom) {
    return Math.pow(2, zoom + 8);
}

export function getBounds(features) {
    let x1 = Infinity,
        x2 = 0,
        y1 = Infinity,
        y2 = 0;

    for (let i = 0; i < features.length; i++) {
        let prop = features[i];
        x1 = Math.min(prop.geometry.coordinates[0], x1);
        x2 = Math.max(prop.geometry.coordinates[0], x2);
        y1 = Math.min(prop.geometry.coordinates[1], y1);
        y2 = Math.max(prop.geometry.coordinates[1], y2);
    }
    return [[x1, y1], [x2, y2]];
}

export function getCenter(bounds){
    let x = bounds[0][0] + (bounds[1][0] - bounds[0][0]) / 2;
    let y = bounds[0][1] + (bounds[1][1] - bounds[0][1]) / 2;
    return [x, y];
}

/**
 * Форматировать число/строку в формат 000 000 000
 * @param {string, number} price
 * @return {string}
 */
export function formatPrice(price) {
    price = typeof price === 'number' ? price : parseFloat(price);
    return price.toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$& ');
}